.text-serif {
  font-family: Georgia, Times New Roman, Times, serif;
}

.capitalized {
  text-transform: capitalize;
}

.card-link {
  cursor: pointer;
}

.hover-thumbnail-card:hover .full-thumbnail {
  visibility: visible;
}

.full-thumbnail {
  visibility: hidden;
}

.ais-RefinementList-labelText, .ais-ToggleRefinement-labelText {
  padding-left: .5em;
}

.ais-CurrentRefinements-category {
  margin-right: .5em;
}

.ais-Stats {
  text-align: center;
  margin-bottom: .5em;
}

.nav-link:focus {
  box-shadow: none;
  outline: none;
}

/*# sourceMappingURL=audio.e2a66825.css.map */
