.text-serif {
  font-family: Georgia, "Times New Roman", Times, serif;
}

.capitalized {
  text-transform: capitalize;
}

.card-link {
  cursor: pointer;
}

.hover-thumbnail-card:hover .full-thumbnail {
  visibility: visible;
}

.full-thumbnail {
  visibility: hidden;
}

.ais-RefinementList-labelText,
.ais-ToggleRefinement-labelText {
  padding-left: 0.5em;
}

.ais-CurrentRefinements-category {
  margin-right: 0.5em;
}

.ais-Stats {
  margin-bottom: 0.5em;
  text-align: center;
}

/* Remove focus around nav buttons https://stackoverflow.com/a/23164601/31605 */
.nav-link:focus {
  outline: none;
  box-shadow: none;
}
